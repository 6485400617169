import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField,
  Grid, RadioGroup, Radio, Tooltip,
  FormControlLabel, IconButton, Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { shopActions } from '../../../redux/shop/shop/shopState';
import DataGrid from '../../../components/templates/DataGrid';
import PublicImageList from '../../../components/templates/publicImageList/PublicImageList';
import NumberInput from '../../../components/atoms/NumberInput';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import {
  FormTitle, RecordBox, Link, FormErrorText, DateTimePicker, FormSwitch,
  TextArea, TimePicker, SuccessSnackbar,
} from '../../../components/atoms/Base';
import detailUrl from '../../../constants/frontUrls';
import Validation from './validation';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import PrefectureInput from '../../../components/atoms/PrefectureInput';
import SystemInfo from '../../../components/templates/SystemInfo';
import Accordion from '../../../components/templates/Accordion';
import SelectReviewDialog from '../common/SelectReviewDialog/SelectReviewDialog';
import { RecordHeaderButton } from '../../../components/templates/RecordHeader';

const DEFAULT_FORM = {
  id: '',
  code: '',
  accountId: '',
  accountName: '',
  name: '',
  nameHiragana: '',
  nameKatakana: '',
  shopGenre: '',
  menuContent: '',
  prefectureId: '',
  cityId: '',
  postcode: '',
  address: '',
  building: '',
  phone: '',
  emergencyPhone: '',
  fax: '',
  email: '',
  cqreeReceptionPhone: '',
  receptionStartTime: '',
  receptionEndTime: '',
  deliveryAgencyId: '',
  deliveryAgencyName: '',
  operationManagerName: '',
  remarks: '',
  committedContent: '',
  deliveryStartTime: '',
  deliveryEndTime: '',
  pickupOneStartTime: '',
  pickupOneEndTime: '',
  pickupTwoStartTime: '',
  pickupTwoEndTime: '',
  rsvDeadlineDays: '',
  rsvDeadlineTime: '',
  rsvDeliveryTimeRange: '',
  rsvDeadlineNote: '',
  cateringDeliveryStartTime: '',
  cateringDeliveryEndTime: '',
  cateringPickupOneStartTime: '',
  cateringPickupOneEndTime: '',
  cateringPickupTwoStartTime: '',
  cateringPickupTwoEndTime: '',
  cateringRsvDeadlineDays: '',
  cateringRsvDeadlineTime: '',
  cateringRsvDeadlineNote: '',
  paymentMethodName: '',
  published: false,
  displayOrder: '',
  createdByName: '',
  createdAt: '',
  updatedByName: '',
  updatedAt: '',
  images: [],
};

const weekCount = [
  { count: 1, label: '第一' },
  { count: 2, label: '第二' },
  { count: 3, label: '第三' },
  { count: 4, label: '第四' },
  { count: 5, label: '第五' },
];
const dayOfWeeks = [
  { id: 1, label: '月' },
  { id: 2, label: '火' },
  { id: 3, label: '水' },
  { id: 4, label: '木' },
  { id: 5, label: '金' },
  { id: 6, label: '土' },
  { id: 0, label: '日' },
  { id: 7, label: '祝' },
];

export default function ShopDetailInfoCard(props) {
  const { id, isDisabled } = props;
  const dispatch = useDispatch();
  const dbData = useSelector((state) => state.shopStore.data);
  const isSaveSuccess = useSelector((state) => state.shopStore.isSaveSuccess);
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isEdit, setEdit] = React.useState(false);
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [shopData, setShopData] = React.useState(DEFAULT_FORM);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [imageList, setImageList] = React.useState([]);
  const [tempImageList, setTempImageList] = React.useState([]);
  const [tempPaymentMethods, setTempPaymentMethods] = React.useState([]);
  const [regularHolidays, setRegularHolidays] = React.useState([]);
  const [tempRegularHolidays, setTempRegularHolidays] = React.useState([]);
  const [isSelectOpen, setSelectOpen] = React.useState(false);

  const search = () => {
    dispatch(shopActions.getData({ id }));
  };

  React.useEffect(() => {
    if (dbData && dbData.data) {
      setForm(dbData.data);
      setShopData(dbData.data);
      setImageList(dbData.images);
      setTempImageList(dbData.images);
      setTempPaymentMethods(dbData.paymentMethods);
      setRegularHolidays(dbData.regularHolidays);
      setTempRegularHolidays(dbData.regularHolidays);
      setErrorMessages(DEFAULT_FORM);
    }
  }, [dbData]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      search();
      setSuccessOpen(true);
      setSuccessMessage('保存しました');
      dispatch(shopActions.resetFlg());
    }
  }, [isSaveSuccess]);

  const onEdit = () => {
    search();
    setForm(DEFAULT_FORM);
    setEdit(true);
  };

  const onClose = () => {
    setTempImageList(imageList);
    setEdit(false);
  };

  const addImg = (value) => {
    let imgArray = [];

    const exists = tempImageList.some((img) => img.id === value.id);

    if (exists) {
      imgArray = tempImageList.map((img) => (img.id === value.id ? value : img));
    } else {
      const inputVal = { ...value, displayOrder: tempImageList.length + 1 };
      imgArray = [...tempImageList, inputVal];
    }

    setTempImageList(imgArray);
  };

  const deleteImg = (deleteId) => {
    const imgArray = tempImageList.filter((img) => img.id !== deleteId);
    setTempImageList(imgArray);
  };

  const onSuccessSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrorMessages({ ...errorMessages, [name]: Validation.formValidate(name, value) });
  };

  const onChangeMethods = (event) => {
    const { value } = event.target;
    setTempPaymentMethods(value);
  };

  const onClick = (event, count, dayOfWeekId) => {
    // dbに登録済の休日
    const baseIndex = regularHolidays.findIndex((holiday) => holiday.weekCount === count
      && holiday.dayOfWeek === dayOfWeekId);
    // 画面で操作してるリスト
    const index = tempRegularHolidays.findIndex((holiday) => holiday.weekCount === count
      && holiday.dayOfWeek === dayOfWeekId);
    const tempList = [...tempRegularHolidays];
    let tempScreen = {};

    if (index < 0) {
      tempScreen = baseIndex < 0
        ? { shopId: id, weekCount: count, dayOfWeek: dayOfWeekId }
        : regularHolidays[baseIndex];
      tempList.push(tempScreen);
    } else {
      tempList.splice(index, 1);
    }
    setTempRegularHolidays(tempList);
  };

  const getUploadTargetFile = () => {
    const uploadFiles = [];
    tempImageList.forEach((img) => {
      if (img.isNew) {
        uploadFiles.push(img[0]);
      }
    });
    return uploadFiles;
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;
    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    setErrorMessages(tempMessage);
    return isError;
  };

  const onSave = () => {
    if (beforeSaveCheck()) return;
    const paymentMethods = [];
    tempPaymentMethods.forEach((method) => {
      paymentMethods.push({
        shopId: id,
        paymentMethodId: method,
      });
    });
    const uploadImages = getUploadTargetFile();
    const saveForm = {
      id,
      data: form,
      regularHolidays: tempRegularHolidays,
      paymentMethods,
      imgForm: { images: tempImageList },
      imageFiles: uploadImages,
    };
    dispatch(shopActions.saveData(saveForm));
    onClose();
  };

  const onSelect = (_, selected) => {
    const tempForm = {
      ...form,
      reviewId: selected.id,
      reviewTitle: selected.title,
    };
    setSelectOpen(false);
    setForm(tempForm);
  };

  const onReviewDelete = () => {
    const tempForm = {
      ...form,
      reviewId: null,
      reviewTitle: null,
    };
    setForm(tempForm);
  };

  return (
    <>
      <RecordHeaderButton
        edit={isEdit}
        disabled={isDisabled}
        onEdit={onEdit}
      />
      <RecordBox>
        <Accordion title="基本情報">
          <DataGrid title="店舗コード" value={shopData.code} />
          <DataGrid
            title="加盟企業"
            value={(
              <Link to={detailUrl.ACCOUNT_DETAIL + shopData.accountId}>
                {shopData.accountName}
              </Link>
            )}
          />
          <DataGrid title="インボイス" value={shopData.invoiceTargeted ? '対象' : '非対象'} />
          <DataGrid title="店舗名" value={shopData.name} />
          <DataGrid title="店舗名ひらがな" isEdit={isEdit} value={shopData.nameHiragana}>
            <TextField value={form.nameHiragana} fullWidth name="nameHiragana" onChange={onChange} inputProps={{ maxLength: 100 }} />
          </DataGrid>
          <DataGrid title="店舗名カナ" isEdit={isEdit} value={shopData.nameKatakana}>
            <TextField value={form.nameKatakana} fullWidth name="nameKatakana" onChange={onChange} inputProps={{ maxLength: 100 }} />
          </DataGrid>
          <DataGrid title="店舗ジャンル" isEdit={isEdit} value={shopData.shopGenre}>
            <TextField value={form.shopGenre} fullWidth name="shopGenre" onChange={onChange} inputProps={{ maxLength: 45 }} />
          </DataGrid>
          <DataGrid title="店舗紹介文" isEdit={isEdit} value={shopData.introduction}>
            <TextArea value={form.introduction} name="introduction" fullWidth onChange={onChange} />
          </DataGrid>
          <DataGrid title="メニュー紹介" isEdit={isEdit} value={shopData.menuContent}>
            <TextArea value={form.menuContent} name="menuContent" fullWidth onChange={onChange} />
          </DataGrid>
          <DataGrid title="おすすめコメント" isEdit={isEdit} value={shopData.committedContent}>
            <TextArea value={form.committedContent} fullWidth name="committedContent" onChange={onChange} />
          </DataGrid>
        </Accordion>
        <Accordion title="その他基本情報">
          <DataGrid title="郵便番号" isEdit={isEdit} value={shopData.postcode}>
            <TextField value={form.postcode} fullWidth name="postcode" onChange={onChange} error={!!errorMessages.postcode} inputProps={{ maxLength: 10 }} />
            <FormErrorText>{errorMessages.postcode}</FormErrorText>
          </DataGrid>
          <PrefectureInput form={shopData} inputForm={form} isEdit={isEdit} setForm={setForm} addressColumnName="address" addressColumnLabel="町名・番地" />
          <DataGrid title="建物" isEdit={isEdit} value={shopData.building}>
            <TextField value={form.building} fullWidth name="building" onChange={onChange} inputProps={{ maxLength: 50 }} />
          </DataGrid>
          <DataGrid title="電話番号" isEdit={isEdit} value={shopData.phone}>
            <TextField value={form.phone} fullWidth name="phone" onChange={onChange} error={!!errorMessages.phone} inputProps={{ maxLength: 20 }} />
            <FormErrorText>{errorMessages.phone}</FormErrorText>
          </DataGrid>
          <DataGrid title="FAX" isEdit={isEdit} value={shopData.fax}>
            <TextField value={form.fax} fullWidth name="fax" onChange={onChange} inputProps={{ maxLength: 20 }} />
          </DataGrid>
          <DataGrid title="メールアドレス" isEdit={isEdit} value={shopData.email}>
            <TextField value={form.email} fullWidth name="email" onChange={onChange} error={!!errorMessages.email} inputProps={{ maxLength: 255 }} />
            <FormErrorText>{errorMessages.email}</FormErrorText>
          </DataGrid>
          <DataGrid title="運営責任者" isEdit={isEdit} value={shopData.operationManagerName}>
            <TextField value={form.operationManagerName} fullWidth name="operationManagerName" onChange={onChange} inputProps={{ maxLength: 50 }} />
          </DataGrid>
          <DataGrid title="緊急連絡先電話番号" isEdit={isEdit} value={shopData.emergencyPhone}>
            <TextField value={form.emergencyPhone} fullWidth name="emergencyPhone" onChange={onChange} error={!!errorMessages.emergencyPhone} inputProps={{ maxLength: 15 }} />
            <FormErrorText>{errorMessages.emergencyPhone}</FormErrorText>
          </DataGrid>
          <DataGrid title="Cqree受付電話番号" value={shopData.cqreeReceptionPhone} isEdit={isEdit}>
            <TextField value={form.cqreeReceptionPhone} fullWidth name="cqreeReceptionPhone" onChange={onChange} error={!!errorMessages.cqreeReceptionPhone} inputProps={{ maxLength: 15 }} />
            <FormErrorText>{errorMessages.cqreeReceptionPhone}</FormErrorText>
          </DataGrid>
          <DataGrid title="代行会社" isEdit={isEdit} value={shopData.deliveryAgencyName}>
            <DataSelectList
              name="deliveryAgencyId"
              target="delivery-agencies"
              parameter={{}}
              value={form.deliveryAgencyId}
              onChange={onChange}
              fullWidth
            />
          </DataGrid>
          <DataGrid title="支払方法" isEdit={isEdit} value={shopData.paymentMethodName}>
            <DataMultiSelectList
              name="statuses"
              target="payment-methods"
              values={tempPaymentMethods}
              onChange={onChangeMethods}
              fullWidth
            />
          </DataGrid>
        </Accordion>
        <Accordion title="営業情報">
          <DataGrid title="受付開始時間" isEdit={isEdit} value={shopData.receptionStartTime}>
            <TimePicker value={form.receptionStartTime} name="receptionStartTime" onChange={onChange} error={!!errorMessages.receptionStartTime} />
            <FormErrorText>{errorMessages.receptionStartTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="受付終了時間" isEdit={isEdit} value={shopData.receptionEndTime}>
            <TimePicker value={form.receptionEndTime} name="receptionEndTime" onChange={onChange} error={!!errorMessages.receptionEndTime} />
            <FormErrorText>{errorMessages.receptionEndTime}</FormErrorText>
          </DataGrid>
          <Grid container spacing={1} mb={2} style={{ wordBreak: 'break-all' }}>
            <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <FormTitle title="定休日" />
            </Grid>
            <Grid item xs={12} md={8}>
              {!isEdit && weekCount.map((week) => (
                <Box key={week.label} mb={1}>
                  <Box>
                    {week.label}
                    &nbsp;&nbsp;
                    {dayOfWeeks.map((dayOfWeek) => {
                      let cmp;
                      const isChecked = regularHolidays.some(
                        (holiday) => (holiday.weekCount === week.count
                          && holiday.dayOfWeek === dayOfWeek.id),
                      );
                      if (isChecked) {
                        cmp = (dayOfWeek.label);
                      }
                      return (cmp);
                    })}
                  </Box>
                </Box>
              ))}
              {isEdit && weekCount.map((week) => (
                <Box mb={1} key={week.label}>
                  <Box>
                    {week.label}
                  </Box>
                  <Box>
                    {dayOfWeeks.map((dayOfWeek) => {
                      const isChecked = tempRegularHolidays.some(
                        (holiday) => (holiday.weekCount === week.count
                          && holiday.dayOfWeek === dayOfWeek.id),
                      );
                      return (
                        <FormControlLabel
                          control={<Checkbox checked={isChecked} onChange={(event) => onClick(event, week.count, dayOfWeek.id)} name="screenName" />}
                          label={dayOfWeek.label}
                          key={week.label + dayOfWeek.label}
                        />
                      );
                    })}
                  </Box>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Accordion>
        <Accordion title="オードブル情報">
          <DataGrid title="配送開始時間" isEdit={isEdit} value={shopData.deliveryStartTime}>
            <TimePicker value={form.deliveryStartTime} name="deliveryStartTime" onChange={onChange} error={!!errorMessages.deliveryStartTime} />
            <FormErrorText>{errorMessages.deliveryStartTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="配送終了時間" isEdit={isEdit} value={shopData.deliveryEndTime}>
            <TimePicker value={form.deliveryEndTime} name="deliveryEndTime" onChange={onChange} error={!!errorMessages.deliveryEndTime} />
            <FormErrorText>{errorMessages.deliveryEndTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="オードブル予約受付締切日" isEdit={isEdit} value={shopData.rsvDeadlineDays}>
            <NumberInput value={form.rsvDeadlineDays} fullWidth name="rsvDeadlineDays" onChange={onChange} />
          </DataGrid>
          <DataGrid title="オードブル予約受付締切時間" isEdit={isEdit} value={shopData.rsvDeadlineTime}>
            <TimePicker value={form.rsvDeadlineTime} name="rsvDeadlineTime" onChange={onChange} error={!!errorMessages.rsvDeadlineTime} />
            <FormErrorText>{errorMessages.rsvDeadlineTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="オードブルお届け時間幅" isEdit={isEdit} value={shopData.rsvDeliveryTimeRange}>
            <RadioGroup row value={form.rsvDeliveryTimeRange} name="rsvDeliveryTimeRange" onChange={onChange} defaultValue="30">
              <FormControlLabel value="30" control={<Radio />} label="30" />
              <FormControlLabel value="60" control={<Radio />} label="60" />
            </RadioGroup>
          </DataGrid>
          <DataGrid title="オードブル予約受付締切備考" isEdit={isEdit} value={shopData.rsvDeadlineNote}>
            <TextArea value={form.rsvDeadlineNote} fullWidth name="rsvDeadlineNote" onChange={onChange} />
          </DataGrid>
        </Accordion>
        <Accordion title="ケータリング情報">
          <DataGrid title="ケータリングお届け開始時間" isEdit={isEdit} value={shopData.cateringDeliveryStartTime}>
            <TimePicker value={form.cateringDeliveryStartTime} name="cateringDeliveryStartTime" onChange={onChange} error={!!errorMessages.cateringDeliveryStartTime} />
            <FormErrorText>{errorMessages.cateringDeliveryStartTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="ケータリングお届け終了時間" isEdit={isEdit} value={shopData.cateringDeliveryEndTime}>
            <TimePicker value={form.cateringDeliveryEndTime} name="cateringDeliveryEndTime" onChange={onChange} error={!!errorMessages.cateringDeliveryEndTime} />
            <FormErrorText>{errorMessages.cateringDeliveryEndTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="ケータリング予約受付締切日" isEdit={isEdit} value={shopData.cateringRsvDeadlineDays}>
            <NumberInput value={form.cateringRsvDeadlineDays} fullWidth name="cateringRsvDeadlineDays" onChange={onChange} />
          </DataGrid>
          <DataGrid title="ケータリング予約受付締切時間" isEdit={isEdit} value={shopData.cateringRsvDeadlineTime}>
            <TimePicker value={form.cateringRsvDeadlineTime} name="cateringRsvDeadlineTime" onChange={onChange} error={!!errorMessages.cateringRsvDeadlineTime} />
            <FormErrorText>{errorMessages.cateringRsvDeadlineTime}</FormErrorText>
          </DataGrid>
          <DataGrid title="ケータリング予約受付締切備考" isEdit={isEdit} value={shopData.cateringRsvDeadlineNote}>
            <TextArea value={form.cateringRsvDeadlineNote} fullWidth name="cateringRsvDeadlineNote" onChange={onChange} />
          </DataGrid>
        </Accordion>
        <Accordion title="画像情報">
          <DataGrid title="店舗画像" isEdit>
            <PublicImageList
              itemDatas={tempImageList}
              isEdit={isEdit}
              onAddImg={addImg}
              onDelete={deleteImg}
              onChange={(e) => setTempImageList(e)}
              isNeedIntroduction
              isNeedImageType
            />
          </DataGrid>
        </Accordion>
        <Accordion title="公開情報">
          <DataGrid title="表示順" isEdit={isEdit} value={shopData.displayOrder}>
            <NumberInput value={form.displayOrder} fullWidth name="displayOrder" onChange={onChange} />
          </DataGrid>
          <DataGrid title="公開" isEdit={isEdit} value={shopData.published ? '公開' : '非公開'}>
            <FormSwitch checked={form.published} name="published" onChange={onChange} label="公開する" />
          </DataGrid>
          <DataGrid title="公開日時" value={shopData.publishDt} isEdit={isEdit}>
            <DateTimePicker error={!!errorMessages.publishDt} value={form.publishDt} name="publishDt" onChange={onChange} />
            <FormErrorText>{errorMessages.publishDt}</FormErrorText>
          </DataGrid>
        </Accordion>
        <Accordion title="ピックアップ口コミ">
          <DataGrid
            title="口コミ"
            value={(
              <Link to={detailUrl.REVIEW_DETAIL + shopData.reviewId}>
                {shopData.reviewTitle}
              </Link>
            )}
            isEdit={isEdit}
          >
            <Box display="flex">
              <Box display="flex" alignItems="center" mr={1}>{form.reviewTitle}</Box>
              {form.reviewId && (
                <Tooltip title="口コミ削除">
                  <IconButton size="small" onClick={onReviewDelete}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {!form.reviewId && (
              <Box mt={2}>
                <Button size="small" variant="contained" onClick={() => setSelectOpen(true)}>口コミ選択</Button>
              </Box>
            )}
          </DataGrid>
        </Accordion>
        <Accordion title="備考">
          <DataGrid title="備考" isEdit={isEdit} value={shopData.remarks}>
            <TextArea value={form.remarks} fullWidth name="remarks" onChange={onChange} />
          </DataGrid>
        </Accordion>
        {!isEdit && shopData.id && (
        <Box mt={2}><SystemInfo data={shopData} /></Box>
        )}

        {isEdit && (
          <Box position="sticky" bottom={0} left={0} zIndex={99} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <Button color="inherit" variant="contained" size="small" onClick={onClose} style={{ marginRight: '10px' }}>キャンセル</Button>
            <Button variant="contained" size="small" onClick={onSave}>保存</Button>
          </Box>
        )}
      </RecordBox>
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSuccessSnackbarClose}
        message={successMessage}
      />
      <SelectReviewDialog
        isOpen={isSelectOpen}
        onSelect={onSelect}
        shopId={shopData.id}
        onClose={() => setSelectOpen(false)}
      />
    </>
  );
}
