import React from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, Box } from '@mui/material';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { shopActions } from '../../../redux/shop/shop/shopState';
import { FormTitle } from '../../../components/atoms/Base';

const DEFAULT_SEARCH_CONDITION = {
  shopCode: '',
  name: '',
  phone: '',
  published: false,
  keyword: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'code', label: '店舗コード' },
  { key: 'accountId', label: '加盟企業ID' },
  { key: 'accountName', label: '加盟企業名' },
  { key: 'invoiceTargeted', label: 'インボイス対象' },
  { key: 'name', label: '店舗名' },
  { key: 'nameHiragana', label: '店舗名ひらがな' },
  { key: 'nameKatakana', label: '店舗名カタカナ' },
  { key: 'shopGenre', label: '店舗ジャンル自由入力項目' },
  { key: 'introduction', label: '店舗紹介' },
  { key: 'menuContent', label: 'メニュー紹介' },
  { key: 'cqreeReceptionPhone', label: 'Cqree受付電話番号' },
  { key: 'deliveryAgencyId', label: '配送代行会社ID' },
  { key: 'deliveryAgencyName', label: '配送代行会社名' },
  { key: 'phone', label: '電話番号' },
  { key: 'fax', label: 'Fax' },
  { key: 'email', label: 'メールアドレス' },
  { key: 'operationManagerName', label: '運営責任者' },
  { key: 'emergencyPhone', label: '緊急時電話番号' },
  { key: 'postcode', label: '郵便番号' },
  { key: 'prefectureId', label: '都道府県ID' },
  { key: 'prefectureName', label: '都道府県名' },
  { key: 'cityId', label: '市区町村ID' },
  { key: 'cityName', label: '市区町村名' },
  { key: 'address', label: '町名・番地' },
  { key: 'building', label: '建物' },
  { key: 'receptionStartTime', label: '受付開始時間' },
  { key: 'receptionEndTime', label: '受付終了時間' },
  { key: 'remarks', label: '備考' },
  { key: 'committedContent', label: 'おすすめコメント' },
  { key: 'deliveryStartTime', label: '配送開始時間' },
  { key: 'deliveryEndTime', label: '配送終了時間' },
  { key: 'rsvDeadlineDays', label: '予約受付締切日' },
  { key: 'rsvDeadlineTime', label: '予約受付締切時間' },
  { key: 'rsvDeliveryTimeRange', label: 'オードブルお届け時間幅' },
  { key: 'rsvDeadlineNote', label: '予約受付締切備考' },
  { key: 'cateringDeliveryStartTime', label: 'ケータリングお届け開始時間' },
  { key: 'cateringDeliveryEndTime', label: 'ケータリングお届け終了時間' },
  { key: 'cateringRsvDeadlineDays', label: 'ケータリング予約受付締切日' },
  { key: 'cateringRsvDeadlineTime', label: 'ケータリング予約受付締切時間' },
  { key: 'cateringRsvDeadlineNote', label: 'ケータリング予約受付締切備考' },
  { key: 'published', label: '公開' },
  { key: 'publishDt', label: '公開日時' },
  { key: 'displayOrder', label: '表示順' },
  { key: 'salesforceId', label: 'SalesforceID' },
  { key: 'oneWeekClosed', label: '第1定休日' },
  { key: 'twoWeekClosed', label: '第2定休日' },
  { key: 'threeWeekClosed', label: '第3定休日' },
  { key: 'fourWeekClosed', label: '第4定休日' },
  { key: 'fiveWeekClosed', label: '第5定休日' },
  { key: 'paymentMethod', label: '支払方法' },
  { key: 'reviewId', label: 'ピックアップ口コミID' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },

];

class ShopList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'shopCode', label: '店舗コード', sortId: 'shop_code' },
      { id: 'name', label: '店舗名', sortId: 'name' },
      { id: 'displayOrder', label: '表示順', sortId: 'display_order' },
      { id: 'prefectureName', label: '都道府県', sortId: 'prefecture_name' },
      { id: 'cityName', label: '市区町村', sortId: 'city_name' },
      { id: 'phone', label: '電話番号', sortId: 'phone' },
      { id: 'email', label: 'メール', sortId: 'email' },
      { id: 'published', label: '公開状況', sortId: 'published' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;
    if (props.shopInfo.searchCondition) {
      searchCondition = props.shopInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      download: {
        headerItems: CSV_HEADER,
        fileName: '店舗',
        done: false,
        datas: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shopInfo.datas !== this.props.shopInfo.datas) {
      if (this.props.shopInfo.datas) {
        this.setDatas(this.props.shopInfo.datas);
      }
    }
    if (prevProps.shopInfo.csvDatas !== this.props.shopInfo.csvDatas) {
      if (this.props.shopInfo.csvDatas) {
        this.setCsvDatas(this.props.shopInfo.csvDatas);
      }
    }
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(shopActions.setSearchCondition(searchCondition));
    this.props.dispatch(shopActions.getDatas(searchCondition));
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(shopActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(shopActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="shop"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="店舗名"
          simpleAreaOne={(
            <TextField fullWidth size="small" autoComplete="off" value={searchCondition.name} name="name" onChange={this.onChange} />
          )}
          simpleAreaTwoText="電話番号"
          simpleAreaTwo={(
            <TextField fullWidth size="small" autoComplete="off" value={searchCondition.phone} name="phone" onChange={this.onChange} />
          )}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTitle title="店舗コード" />
              <TextField fullWidth size="small" autoComplete="off" value={searchCondition.shopCode} name="shopCode" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="メールアドレス" />
              <TextField fullWidth autoComplete="off" size="small" value={searchCondition.email} name="email" onChange={this.onChange} />
            </Grid>
          </Grid>
          <Box mt={1}>
            <FormTitle title="フリーワード" attention="店舗名ひらがな、店舗名カタカナ、店舗ジャンル自由入力項目" />
            <TextField fullWidth autoComplete="off" size="small" value={searchCondition.freeWord} name="freeWord" onChange={this.onChange} />
          </Box>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.SHOP_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="店舗"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd
          isNotDelete
          condition={searchCondition}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  shopInfo: state.shopStore,
});

export default connect(mapStateToProps)(ShopList);
